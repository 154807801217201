import React, { Component, Fragment } from 'react';

import styles from './email.less';

export default class EmailUnsubscribe extends Component {
  render() {
    return (
      <Fragment>
        <div className={styles.UnsubscribeBox}>
          <div className={styles.UnsubscribeMain}>
            <h1>DSers.com</h1>
            <div className={styles.UnsubscribeCont}>
              <h3>Unsubscribe Successfully</h3>
              <p>You will no longer receive email marketing from this list</p>
              <p className={styles.contHref}>
                <a href="https://www.dsers.com" target="_blank">
                  Go to DSers
                </a>
              </p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
